import myJson from '../json/appsettings.json';

class AppSettingsService{

    getSettings() {
        return myJson;
    }

    getConfiguration() {
        return new Promise((resolve, reject) => {
            fetch("/json/configuration.json")
            .then(response => response.json())
            .then(file => resolve(file))
            .catch(error => reject(error))
        });
    }
}

export default new AppSettingsService();