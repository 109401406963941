<template>
    <v-container>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      
      <v-container v-else fluid>
        <v-row class="px-0 pt-2" v-for="(item, i) in championships"
              :key="'item-'+i">
            <v-card class="pointer" style="width: 100%" @click="goToStanding(item)">
              <v-card-subtitle class="px-1 py-4">
                <v-icon>mdi-chevron-right</v-icon>
                <span style="text-decoration: underline"> {{ item.championship }} </span>
              </v-card-subtitle>
            </v-card>
          
        </v-row>
      </v-container>
    </v-container>
  </template>
  
  <script>
    import { services } from '../services/serviceBuilder'
  
    export default {
      name: 'StandingsPage',
      data: () => ({
        loading: true,
        championships: []
      }), 
      methods: {
        getChampionships() {
          var that = this;
          that.championships = [];
          that.loading = true;
  
          services.ApiCallerChampionships.championships()
            .then(response => {
              that.championships = Object.assign([], response.data.championships);          
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              that.loading = false;
            })
        },
        goToStanding(item) {
          this.$router.push({name: 'standing', params: { championshipId: item.championshipId }});
        }
      },
      created() {
        this.getChampionships();
      }
    }
  </script>
  