<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="'/logo.png'"
            class="my-3"
            contain
            height="200"
          />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Benvenuti in {{appName}}
        </h1>
        <div>
          <v-btn color="primary" @click="goToSchedule">Risultati</v-btn>
        </div>
        <div>
          <v-btn color="primary mt-2" @click="goToStandings">Campionati</v-btn>
        </div>        
        <div class="mt-4">
          Iscritivi ai canali telegram per ricevere le notifiche live
        </div>
        <div v-for="(channel,i) in telegramChannels" :key="'channel-' + i">
          <a :href="channel.href">{{channel.name}}</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AppSettingsService from '../services/appSettingsService';
  export default {
    name: 'HomePage',

    data: () => ({
      appName: '',
      telegramChannels: []
    }),
    methods: {
      goToSchedule: function() {
        this.$router.push({name: 'schedule'});
      },
      goToStandings: function() {
        this.$router.push({name: 'standings'});
      },
    },
    created() {
      var that = this;
      AppSettingsService.getConfiguration()
        .then(conf => {
          that.appName = conf.appName;
          that.telegramChannels = conf.telegramChannels;
        })
    }
  }
</script>
