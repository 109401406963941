<template>
  <div v-if="sponsorVisible">
    <div class="white--text text-center mt-4 py-4 primary sponsorContainer">
      Sponsor By
    </div>
    <v-carousel height="250" cycle interval="3000" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="'img-' + i"
        class="pointer"
        :src="item.src"
        contain
        @click.native="imageClick(item)"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { services } from '../services/serviceBuilder'
import AppSettingsService from '../services/appSettingsService';

export default {
  name: 'SponsorComponent',
  data: () => ({
    sponsorVisible: true,
    items: []
  }),
  computed: {
    
  },
  methods: {
    checkCondition(name) {
      return (name != 'admin' && name != 'login');
    },
    imageClick(item) {
      if(item.url){
        window.open(item.url, '_blank');
      }
    },
    shuffledArray: function(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
  },
  watch:{
    $route (to){
      this.sponsorVisible = this.checkCondition(to.name);
    }
  },
  created() {
    var that = this;
    AppSettingsService.getConfiguration()
      .then(conf => {
        var sponsors = conf.sponsors.map(x => {
          x.src = services.apiCallerHelper.sponsor(x.name);
          return x;
        });
        that.items = that.shuffledArray(sponsors);
        that.sponsorVisible =  that.checkCondition(this.$route.name);
      })
  }
};
</script>

<style scoped>

.sponsorContainer {
  font-size: 25px; 
  font-weight: bold;
}

</style>
