import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist"

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'user', // unique key for saving state properties in the browser storage
  storage: window.localStorage, // select the type of storage used (eg - localStorage, sessionStorage, etc)
  reducer: (state) => ({ user: state.user }) // select the state properties that you want persisted 
})

export const store = new Vuex.Store({
  state: {
    user: null
  },
  plugins: [vuexPersist.plugin], // adding vuex-persist as a store plugin
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    }
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  actions: {},
  modules: {},
});
