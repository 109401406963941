<template>
  <v-app :class="{transparent: isBar}">
    <Header v-if="!isBar" :app-name="appName"></Header>

    <v-main>
      <div class="d-flex flex-column fill-height">
        <router-view/>
        <Sponsor v-if="!isBar" class="mt-auto"></Sponsor>
      </div>
    </v-main>

    <Footer v-if="!isBar"></Footer>
  </v-app>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import SponsorComponent from './components/SponsorComponent.vue'
import AppSettingsService from './services/appSettingsService';

export default {
  name: 'App',

  components: {
    Header : HeaderComponent,
    Footer : FooterComponent,
    Sponsor : SponsorComponent
  },

  data: () => ({
    appName: ''
  }),

  computed: {
    isBar: function() {
     return this.$route.name === 'bar'
    }
  },

  methods: {
  },

  created() {
    var that = this;
    AppSettingsService.getConfiguration()
      .then(conf => {
        that.appName = conf.appName;
        var title = document.getElementById('bodyTitle');
        title.innerHTML = that.appName;
      })
  }

};
</script>


