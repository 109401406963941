<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <v-container v-else fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="add()" color="primary"><v-icon>mdi-plus</v-icon>Aggiungi</v-btn>
      </v-row>
      <v-row class="px-0 pt-4">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(group, i) in groupedResults"
            :key="'group-'+i"
          >
            <v-expansion-panel-header>
              {{group.championship}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(item, key, j) in group.results" 
                :key="'result-'+j">
                <v-col cols="12" class="px-0 pb-0">
                  <MatchItem :matchKey="key" :matchInfo="item" :edit="true" :date="true"
                    @click="detailResult" @remove="removeResult"></MatchItem>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span v-if="dialogType == 'add'">Aggiungi Partita</span>
          <span v-if="dialogType == 'edit'">Modifica Partita</span>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="dialogItem.key" label="Chiave" :disabled="dialogType == 'edit'"></v-text-field>
          <v-text-field v-model="dialogItem.teamHome" label="Squadra Casa" :disabled="dialogType == 'edit'"></v-text-field>
          <v-text-field v-model="dialogItem.teamAway" label="Squadra Trasferta" :disabled="dialogType == 'edit'"></v-text-field>
          <v-select 
            v-model="dialogItem.championshipInfo" label="Campionato" :disabled="dialogType == 'edit'"
           :items="championships" item-text="championship" item-value="championshipId" append-outer-icon="mdi-plus" return-object
           @click:append-outer="dialogChampionship = true">
          </v-select>
          <v-text-field v-model="dialogItem.date" hint="yyyy-mm-dd hh:mm" label="Data e Ora"></v-text-field>
          <v-text-field v-model="dialogItem.telegramChannel" label="Telegram Channel"></v-text-field>
          <v-text-field v-model="dialogItem.video" label="Video"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!btnSaveEnabled" @click="save()">Salva</v-btn>
          <v-btn @click="detail()">Modifica</v-btn>
          <v-btn color="error" @click="cancel()">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogChampionship"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Aggiungi Campionato</v-card-title>

        <v-card-text>
          <v-text-field v-model="newChampionship.championshipId" label="Id"></v-text-field>
          <v-text-field v-model="newChampionship.championship" label="Campionato"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!btnSaveChampionshipEnabled" @click="saveChampionship()">Salva</v-btn>
          <v-btn color="error" @click="cancelChampionship()">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import MatchItemComponent from '../MatchItemComponent.vue'
  import { services } from '../../services/serviceBuilder'

  export default {
    name: 'AdminScheduleComponent',
    components: {
      MatchItem: MatchItemComponent
    },
    data: () => ({
      loading: true,
      dialog: false,      
      dialogType: null,
      dialogItem: {},
      baseResults: {},
      results: {},
      championships: [],
      dialogChampionship: false,
      newChampionship: {}
    }), 
    computed:{
      btnSaveEnabled: function() {
        return (this.dialogItem && this.dialogItem.key && 
          this.dialogItem.teamHome && this.dialogItem.teamAway &&
          this.dialogItem.championshipInfo && this.dialogItem.date);
      },
      btnSaveChampionshipEnabled: function() {
        return (this.newChampionship && this.newChampionship.championshipId && 
        this.newChampionship.championship);
      },
      groupedResults: function() {
        var grouped = {};
        for (const value of this.championships) {
          grouped[value.championshipId] = {
            championship: value.championship,
            results: {}
          };
        }
        
        for (const [key, value] of Object.entries(this.results)) {
          if(grouped[value.championshipId])
            grouped[value.championshipId].results[key] = value;
        }    
                
        return grouped;
      }
    },
    methods: {
      getChampionships() {
        var that = this;
        that.championships = [];
        that.loading = true;

        services.ApiCallerChampionships.championships()
          .then(response => {
            that.championships = Object.assign([], response.data.championships);          
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      getResults() {
        var that = this;
        that.results = {};
        that.baseResults = {};
        that.loading = true;

        services.apiCallerResult.results(null)
          .then(response => {
            that.results = Object.assign({}, response.data);
            that.baseResults = Object.assign({}, response.data);            
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      saveResults(data) {
        var that = this;
        that.results = {};
        that.baseResults = {};
        that.loading = true;

        services.apiCallerResult.saveResults(data)
          .then(response => {
            that.results = response.data;
            that.baseResults = Object.assign({}, response.data);
            that.$root.$emit('refreshResults', true);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      detail() {
        this.dialog = false;
        this.$emit('detail', this.dialogItem);
      },
      detailResult(item) {
        this.dialogItem = item;

        this.dialogType = "edit";
        this.dialog = true;
      },
      removeResult(key) {
        if(this.results[key]) {
          delete this.results[key];
          this.results = Object.assign({}, this.results);
          this.saveResults({
            events: this.results,
            newEvent: null
          });
        }
      },
      add() {
        this.dialogItem = {};
        this.dialogType = "add";
        this.dialog = true;
      },
      save() {
        this.dialog = false;

        if(this.dialogType == 'add'){
          if(this.results[this.dialogItem.key]) {
            //Check it not exists
            return;
          }
        }
        
        this.results[this.dialogItem.key] = {
          date: this.dialogItem.date,
          championship: this.dialogItem.championshipInfo.championship,
          championshipId: this.dialogItem.championshipInfo.championshipId,
          video: this.dialogItem.video,
          telegramChannel : this.dialogItem.telegramChannel
        }
        
        this.saveResults({
          events: this.results,
          newEvent: this.dialogType == 'add' ? this.dialogItem : null
        });
      },
      cancel() {
        this.dialog = false;   
        this.results = Object.assign({}, this.baseResults);
      },
      saveChampionship() {
        var that = this;
        that.loading = true;
        that.championships.push(this.newChampionship);
        var data = {
          championships: that.championships
        };

        services.ApiCallerChampionships.saveChampionships(data)
          .then(() => {
            that.getChampionships();
            that.cancelChampionship();
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      cancelChampionship() {
        this.dialogChampionship = false;   
        this.newChampionship = {};
      }
    },
    created() {
      this.getChampionships();
      this.getResults();
    }
  }
</script>
