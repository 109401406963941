import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    match(key, championshipId) {
        const url = this.webApiBaseUrl + "match";
        return this.apiCallerHelper.callPost(url, {key: key, championshipId: championshipId});
    }

    results(date) {
        const url = this.webApiBaseUrl + "results";
        return this.apiCallerHelper.callPost(url, {date: date});
    }

    saveResults(data) {
        const url = this.webApiBaseUrl + "results/save";
        return this.apiCallerHelper.callPost(url, data);
    }

    image(key, name) {
        try {
            var keyItems = key.split('_');
            if(keyItems.length >= 2) {
                if(name == 'home') {
                    return '/images/' + keyItems[0] + '.png';
                }
                else if(name == 'away'){
                    return '/images/' + keyItems[1] + '.png';
                } 
            }
        }
        catch(e) {
            return '/images/test.png';
        }
        
    }
}