<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-btn v-if="backVisible" small icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-img 
        alt="Logo"
        class="shrink mr-2 pointer"
        contain
        :src="'/logo.png'"
        transition="scale-transition"
        width="40"
        @click="goHome"
      />

      <h2 v-if="!isMobile">{{appName}}</h2>
    </div>

    <v-spacer></v-spacer>

    <v-btn text v-if="username == null" @click="goLogin">
      <span class="mr-2">Login</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <template v-else>
    <v-btn text @click="goLogin">
      {{username}}   
    </v-btn>
    <v-icon @click="goLogout">mdi-logout</v-icon>
    </template>  
  </v-app-bar>
</template>

<script>
import { services } from '../services/serviceBuilder'

export default {
  name: 'HeaderComponent',

  data: () => ({
    isMobile: null,
    backRoutes: ["result", "schedule", "standings", "standing"]
  }),
  props: {
    appName: null
  },
  computed: {
    backVisible: function() {
      return this.backRoutes.includes(this.$route.name);
    },
    username: function() {
      return services.apiCallerAdmin.getUsername();
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    goBack() {
      this.$router.back();
    },
    goLogin() {
      var url = '/login';
      if(this.username != null) {
        url = '/admin';
      }
      this.$router.push(url);
    },
    goLogout() {
      services.apiCallerAdmin.setUsername(null);
      this.$router.push("/");
    },
    onResize() {
      var mobile = window.innerWidth < 600;
      if(this.isMobile != mobile) {
        this.isMobile = mobile;
        this.$root.$emit('isMobile', mobile);
        this.$vuetify.goTo(0);
      }      
    }
  },
  created() {
    this.isMobile = window.innerWidth < 600;
    window.addEventListener('resize', this.onResize, { passive: true });
  }
};
</script>