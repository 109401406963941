<template>
    <div v-if="matchToEdit">
        <v-row class="text-center">
            <v-col cols="6" class="px-0 pt-0">
                <div class="teamName">{{match.teamHome.name}}</div>
            </v-col>
            <v-col cols="6" class="px-0 pt-0">
                <div class="teamName">{{match.teamAway.name}}</div>
            </v-col>
        </v-row>
        <v-row class="pt-0 borderPrimary">
            <v-tabs fixed-tabs v-model="tab">
                <v-tab key="tab-0" style="max-width: 100%">
                    Formazioni
                </v-tab>
                <v-tab key="tab-1" style="max-width: 100%">
                    Riassunto
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" style="width:100%">
                <v-tab-item key="tab-0">
                    <v-row class="text-center pt-0">
                    <v-col cols="6">
                        <v-data-table
                            dense
                            :headers="homeColumns"
                            :items="matchToEdit.teamHome.entries"
                            item-key="number"
                            class="elevation-1"
                            :items-per-page="itemsGrid"
                            hide-default-footer
                            >
                            <template v-slot:[`item.exclusions`]="{ item }">
                                <span :class='{live: item .exclusions == 3}'>{{item.exclusions}}</span>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div v-if="editNames" class="d-flex">
                                  <v-text-field v-model="item.name" @change="setName('Home', item)"></v-text-field>
                                  <img width="24px" v-if="getGoalkeeperImage(item)" :class="{pointer : item.number == 13}" 
                                    :src="getGoalkeeperImage(item)" @click="setName('Home', item, true)">
                                </div>
                                <div v-else class="d-flex">
                                  {{item.name}}    
                                  <img width="24px" v-if="item.goalkeeper || item.number == 1" src="../assets/goalkeeper.svg">                                 
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn icon color="primary" @click="clickEdit('Home', item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </template>
                        </v-data-table>
                        <v-text-field v-if="editNames" label="Alleatore" v-model="matchToEdit.teamHome.coach"  @change="setCoachName('Home', match.teamHome.coach)"></v-text-field>
                        <div v-else style="text-align:left">Allenatore: {{match.teamHome.coach}}</div>
                    </v-col>
                    <v-col cols="6">
                        <v-data-table
                            dense
                            :headers="awayColumns"
                            :items="matchToEdit.teamAway.entries"
                            item-key="number"
                            class="elevation-1"
                            :items-per-page="itemsGrid"
                            hide-default-footer
                        >
                            <template v-slot:[`item.exclusions`]="{ item }">
                                <span :class='{live: item.exclusions == 3}'>{{item.exclusions}}</span>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <div v-if="editNames" class="d-flex" :class="{'flex-row-reverse' : !isMobile }">
                                  <v-text-field v-model="item.name" @change="setName('Away', item)"></v-text-field>
                                  <img width="24px" v-if="getGoalkeeperImage(item)" :class="{pointer : item.number == 13}" 
                                    :src="getGoalkeeperImage(item)" @click="setName('Away', item, true)">
                                </div>
                                <div v-else class="d-flex" :class="{'flex-row-reverse' : !isMobile }">
                                  {{item.name}}    
                                  <img width="24px" v-if="item.goalkeeper || item.number == 1" src="../assets/goalkeeper.svg">                                 
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn icon color="primary" @click="clickEdit('Away', item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </template>
                        </v-data-table>
                        <v-text-field v-if="editNames" label="Alleatore" v-model="matchToEdit.teamAway.coach" @change="setCoachName('Away', match.teamAway.coach)"></v-text-field>
                        <div v-else style="text-align:right">Allenatore: {{match.teamAway.coach}}</div>
                    </v-col>  
                </v-row>   
                <div v-if="edit && editNames" class="d-flex justify-center">
                  <v-switch label="14 Giocatori" class="centerSwitch"
                    v-model="fourteenEnabled" @change="changeFourteenEnabled"
                  ></v-switch>
                </div>
            </v-tab-item>
            <v-tab-item key="tab-1">
                <div v-for="time in [1,2,3,4]" :key="'time-'+time">
                <div class="pa-2 text-center time timeBorder"> {{time}}° Tempo</div>
                <div class="pa-2" v-for="(event,i) in getEvents(time)"  :key="'ev-' + i">
                    <div class="d-flex" :class="{'flex-row-reverse' : event.team == 'Away' }">
                        <template v-if="event.type == 'score'">
                            <v-icon :color="getColor(event)">mdi-water-polo</v-icon>
                            <span>{{event.homeScore + '-' + event.awayScore + ' : ' + event.name}}</span> 
                        </template>
                        <template v-else>
                            <v-icon :color="getColor(event)">mdi-swim</v-icon>
                            <span>{{event.homeScore + '-' + event.awayScore + ' : Rigore ' + event.name}}</span>
                        </template>
                        <v-icon v-if="edit" color="red" @click="deleteEvent(time,i)">mdi-delete</v-icon>
                    </div>
                </div>
                </div>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
    </div>    
</template>

<script>
  export default {
    name: 'ResultPage',

    data: () => ({
      isMobile: true,
      homeColumns: [
        { text: 'N°', align: 'start', sortable: false, value: 'number', width: '10px'},
        { text: 'Nome', value: 'name', sortable: false, },
        { text: 'Goal', value: 'scores', align: 'center', sortable: false, width: '10px' },
        { text: 'Esp.', value: 'exclusions', align: 'center', sortable: false, width: '10px' }
      ],
      awayColumns: [
        { text: 'N°', align: 'end', sortable: false, value: 'number', width: '10px' },
        { text: 'Nome', value: 'name', align: 'end', sortable: false },
        { text: 'Goal', value: 'scores', align: 'center', sortable: false, width: '10px' },
        { text: 'Esp.', value: 'exclusions', align: 'center', sortable: false, width: '10px' }
      ],
      matchToEdit: null,
      fourteenEnabled: false,
      tab: null
    }),
    props: {
        match: {
            type: Object,
            default: null
        },
        edit: {
            type: Boolean,
            default: false
        },
        editNames: {
            type: Boolean,
            default: false
        }
    },
    watch: {
      match: function(newValue) {
        this.matchToEdit = newValue;
        this.itemsGrid = Math.max(13, this.matchToEdit.teamHome.entries.length, this.matchToEdit.teamAway.entries.length);
        this.fourteenEnabled = this.itemsGrid == 14;
      }
    },
    methods: {
      onResize (isMobile) {
        if(this.isMobile != isMobile) {
          this.awayColumns.reverse();
          this.isMobile = isMobile;
        }
      },
      getEvents(time) {
        if(this.match.events != null)
          return this.match.events[time + "° Tempo"];
        
        return [];
      },
      getColor(event) {
        if(event.add)
          return "primary";
        else 
          return "error";
      },
      getGoalkeeperImage(item) { 
        if(item.goalkeeper || item.number == 1) {
          return require('../assets/goalkeeper.svg');
        }
        else if(item.number == 13) {
          return require('../assets/goalkeeper_black.svg');
        }
      },
      //Edit
      clickEdit(team, item) {
        item.team = team;
        this.$emit('edit', item);
      },
      setName(team, item, goalkeeper = false) {
        var obj = {
          type: "name",
          team : team,
          number : item.number,
          name: item.name,
          goalkeeper: item.goalkeeper,
          telegramChannel : this.match.telegramChannel
        };
        if(goalkeeper) {
          obj.goalkeeper = !item.goalkeeper;
        }
        this.$mqtt.publish('NewEvent/' + this.match.championshipId + '/' + this.match.key, JSON.stringify(obj))
      },
      setCoachName(team, name) {
        var obj = {
          type: "coachName",
          team : team,
          name: name,
          telegramChannel : this.match.telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.match.championshipId + '/' + this.match.key, JSON.stringify(obj))
      },
      deleteEvent(time, index) {
        var obj = {
          type: "deleteEvent",
          eventId: index,
          time: time,
          telegramChannel : this.match.telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.match.championshipId + '/' + this.match.key, JSON.stringify(obj))
      },
      changeFourteenEnabled($event) {
        var obj = {
          type: "changeNumber",
          number: $event ? 14 : 13,
          telegramChannel : this.match.telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.match.championshipId + '/' + this.match.key, JSON.stringify(obj))
      }
    },
    created() {
      var that = this;

      that.matchToEdit = that.match;
      that.itemsGrid = Math.max(13, that.matchToEdit.teamHome.entries.length, that.matchToEdit.teamAway.entries.length);
      that.fourteenEnabled = that.itemsGrid == 14;
      if(that.edit) {
        that.homeColumns.push({ text: 'Azioni', value: 'actions', align: 'center', sortable: false, width: '10px' });
        that.awayColumns.push({ text: 'Azioni', value: 'actions', align: 'center', sortable: false, width: '10px' });
      }

      that.onResize(window.innerWidth < 600);
      that.$root.$on('isMobile', that.onResize);
    }
  }
</script>

<style scoped>
  .teamName {
    font-size: 22px;
    font-weight: bold;
  }
</style>