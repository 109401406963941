import AppSettingsService from './appSettingsService';
import { store } from "../store";

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    login(data) {
        const url = this.webApiBaseUrl + "auth/token";
        return this.apiCallerHelper.callPost(url,data);
    }

    setUsername(username) {
        var user = null;
        if(username) {
            user = {
                username: username,
                date: Date.now()
            };
        }
        store.commit("SET_USER", user);
    }

    getUsername() {
        var user = store.getters.user;
        if(user != null) {
            var diffDays =  Math.floor((Date.now() - user.date) / (1000 * 60 * 60 * 24));
            if(Number.isNaN(diffDays) || diffDays > 1){
                store.commit("SET_USER", null);
                return null;
            }
            return user.username;
        }
        return null;
    }
}