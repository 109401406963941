<template>
  <v-container>
    <div class="text-center" 
      v-if="loading">
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <v-row class="my-1">
        <div class="pointer" @click="goStanding()">
          <v-icon>mdi-chevron-right</v-icon>
          <span style="text-decoration: underline"> {{ championship }} </span>
        </div>
      </v-row>

      <v-row>
        <v-divider></v-divider>
      </v-row>

      <v-row class="text-center px-5">
        <v-col cols="3" md="5" class="py-0">
          <v-img
            :src="homeImage"
            class="my-2"
            contain
            height="150"
          />
        </v-col>
        <v-col cols="6" md="2" class="py-0">
          <h5 class="my-2">{{date}}</h5>
          <div class="result"
            :class="{live: match.status == 'In Corso'}"
            >
            <div class="left">{{match.teamHome.totalScore}}</div>
            <div class="center"> - </div>
            <div class="right">{{match.teamAway.totalScore}}</div>
          </div>
          <h5 v-if="match.status == 'In Corso'">{{match.time}}</h5>
          <v-chip v-if="match.status == 'In Corso'"
            color="red" text-color="white">LIVE
          </v-chip>
        </v-col>
        <v-col cols="3" md="5" class="py-0">
          <v-img
            :src="awayImage"
            class="my-2"
            contain
            height="150"
          />
        </v-col>
      </v-row>

      <ResultTable :match="match"></ResultTable>
    </template>
  </v-container>
</template>

<script>
  import ResultTableComponent from './ResultTableComponent.vue'
 
  import moment from 'moment'
  import { services } from '../services/serviceBuilder'

  export default {
    name: 'ResultPage',

    data: () => ({
      loading: true,
      match: null,
      homeImage: null,
      awayImage: null,
      date: null,
      championship: null,
      tab: null
    }),
    components: {
      ResultTable: ResultTableComponent,
    },
    mqtt: {
      'Event/#' (data, topic) {
        if(!topic.includes(this.$route.params.key))
          return;
        const parsedData = JSON.parse(new TextDecoder().decode(data));
        this.match = parsedData.match;
      }
    },
    methods: {
      getMatch() {
        var that = this;
        services.apiCallerResult.match(that.$route.params.key, that.$route.params.championshipId)
          .then(response => {
            that.match = response.data;
            that.homeImage = services.apiCallerResult.image(that.$route.params.key, 'home');
            that.awayImage = services.apiCallerResult.image(that.$route.params.key, 'away');
            that.date = moment(that.$route.query.date).format("DD/MM/yyyy HH:mm");
            that.championship = that.$route.query.championship;
            that.$mqtt.subscribe('Event/' + that.$route.params.championshipId + '/' + that.$route.params.key);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      goStanding() {
        this.$router.push({name: 'standing', params: { championshipId: this.$route.params.championshipId }});
      },
    },
    created() {
      var that = this;
      that.loading = true;

      if(that.$route.params.key == "" || that.$route.params.championshipId == "") {
        that.$router.push({name: 'schedule'});
      }

      that.getMatch();     
    },
    beforeDestroy() {
      this.$mqtt.unsubscribe('Event/#');
    }
  }
</script>

<style scoped>
  .result {
    font-size: 64px;
    font-weight: bold;
    display: flex;
  }

  .left {
    flex: 1;
    display: flex;
    justify-content: right;
  }

  .center {
    flex: 0.5;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: left;
  }
</style>
