import axios from 'axios';

export default class ApiCallerHelper {
    
    // wrappers to call types
    callGet(urlToCall) {
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url: urlToCall,
                timeout: 2000
            })
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }

    callPost(urlToCall, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: urlToCall,
                data: data,
                timeout: 2000
            })
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }

    sponsor(name) {
        return '/sponsors/' + name + '.png';
    }
}
