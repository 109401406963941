<template>
  <v-container>
    <v-select
      :items="matchList"
      v-model="selectedMatchKey"
      label="Partita"
      @change="getMatch"
    ></v-select>
    <template v-if="selectedMatchKey != null">
      <div class="text-center" 
        v-if="loading">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-row>
          <v-divider></v-divider>
        </v-row>

        <v-row class="text-center px-5">
          <v-col cols="2" class="py-0">
            <v-img
              :src="homeImage"
              class="my-2"
              contain
              height="100"
            />
          </v-col>
          <v-col cols="8" class="py-0">
            <div class="result"
              :class="{live: match.status == 'In Corso'}"
              >
              <div class="left">{{match.teamHome.totalScore}}</div>
              <div class="center"> - </div>
              <div class="right">{{match.teamAway.totalScore}}</div>
            </div>
            <v-select
              :items="statusList"
              v-model="selectedStatus"
              label="Stato Partita"
              @change="setStatus"
            ></v-select>
            <v-select
              :items="timeList"
              v-model="selectedTime"
              label="Tempo Partita"
              @change="setTime"
            ></v-select>
            <div class="d-flex">
              <v-switch label="Modifica Nomi" class="centerSwitch"
                v-model="editNames"
              ></v-switch>
            </div>            
          </v-col>
          <v-col cols="2" class="py-0">
            <v-img
              :src="awayImage"
              class="my-3"
              contain
              height="100"
            />
          </v-col>
        </v-row>

        <ResultTable :match="match" 
          :edit="true" :editNames="editNames"
          @edit="edit($event)">
        </ResultTable>
      </template>

      <v-dialog
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Modifica</v-card-title>

          <v-card-text>
            <div class="centerText py-2">
              <div>Goal</div>
              <span class="pl-9">{{dialogItem.scores}}</span>
              <v-btn icon @click="setScore(dialogItem.team, true, dialogItem)">
                <v-icon color="green">mdi-plus</v-icon>
              </v-btn>
            </div>
            <div class="centerText py-2">
              <div>Rigore</div>
              <v-btn icon @click="setScore(dialogItem.team, false, dialogItem, true)">
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
              <span>{{dialogItem.scores}}</span>
              <v-btn icon @click="setScore(dialogItem.team, true, dialogItem, true)">
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
            </div>
            <div class="centerText py-2">
              <div>Espulsioni</div>
              <v-btn icon @click="setExclusion(dialogItem.team, false, dialogItem)">
                <v-icon color="red">mdi-minus</v-icon>
              </v-btn>
              <span>{{dialogItem.exclusions}}</span>
              <v-btn icon @click="setExclusion(dialogItem.team, true, dialogItem)">
                <v-icon color="green">mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">Chiudi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import ResultTableComponent from '../ResultTableComponent.vue'
  import { services } from '../../services/serviceBuilder'

  export default {
    name: 'AdminResultComponent',

    data: () => ({
      selectedMatchKey: null,
      matches: null,
      matchList:[],
      
      selectedStatus: 'Programmata',
      statusList:['Programmata', 'In Corso', 'Terminata'],
      selectedTime: '1° Tempo',
      timeList: ['1° Tempo', '2° Tempo', '3° Tempo', '4° Tempo'],
      editNames: false,
      loading: true,
      
      match: null,
      homeImage: null,
      awayImage: null,
      
      dialog: false,
      dialogItem: {}
    }),
    props: {
      matchToEdit: {
        type: Object,
        default: null
      }
    },  
    watch: {
      matchToEdit: function(newValue) {
        this.selectedMatchKey = newValue.key;
        if(!this.matches){
          this.matches = { };
        }
        this.matchList = [newValue.key];
        this.matches[newValue.key] = newValue;
        this.getMatch();
      }
    },
    components: {
      ResultTable: ResultTableComponent,
    },
    mqtt: {
      'Event/#' (data, topic) {
        if(!topic.includes(this.selectedMatchKey))
          return;
        const parsedData = JSON.parse(new TextDecoder().decode(data));
        parsedData.match.championshipId = this.matches[this.selectedMatchKey].championshipId;
        this.match = parsedData.match;
      }
    },
    methods: {
      edit(event) {
        this.dialogItem = event
        this.dialog = true;
      },
      getMatches() {
        var that = this;

        services.apiCallerResult.results(new Date().toISOString().substring(0,10))
          .then(response => {            
            that.matchList = [];
            that.matches = response.data.results;
            for(var x in response.data.results) {
              that.matchList.push(x);
            }            
          })
          .catch(error => {
            console.log(error)
          })
      },
      getMatch() {
        var that = this;
        that.$mqtt.unsubscribe('Event/#');
        that.loading = true;
        services.apiCallerResult.match(that.selectedMatchKey, that.matches[that.selectedMatchKey].championshipId)
          .then(response => {
            that.match = response.data;
            that.match.championshipId = that.matches[that.selectedMatchKey].championshipId;
            that.homeImage = services.apiCallerResult.image(that.selectedMatchKey, 'home');
            that.awayImage = services.apiCallerResult.image(that.selectedMatchKey, 'away');
            that.selectedStatus = that.match.status;
            that.selectedTime = that.match.time;
            that.$mqtt.subscribe('Event/' + that.matches[that.selectedMatchKey].championshipId + '/' + that.selectedMatchKey);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      setStatus() {
        var obj = {
          type: "status",
          status : this.selectedStatus,
          telegramChannel : this.matches[this.selectedMatchKey].telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.matches[this.selectedMatchKey].championshipId + '/' + this.selectedMatchKey, JSON.stringify(obj))
      },
      setTime() {
        var obj = {
          type: "time",
          time : this.selectedTime,
          telegramChannel : this.matches[this.selectedMatchKey].telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.matches[this.selectedMatchKey].championshipId + '/' + this.selectedMatchKey, JSON.stringify(obj))
      },
      setScore(team, up, item, isPenalty = false) {
        var obj = {
          type: isPenalty ? "penalty" : "score",
          team : team,
          number : item.number,
          add: up,
          telegramChannel : this.matches[this.selectedMatchKey].telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.matches[this.selectedMatchKey].championshipId + '/' + this.selectedMatchKey, JSON.stringify(obj))
        this.dialog = false;
      },
      setExclusion(team, up, item) {
        if((item.exclusions == 3 && up) || (item.exclusions == 0 && !up)){
          return;
        }
        var obj = {
          type: "exclusion",
          team : team,
          number : item.number,
          add: up,
          telegramChannel : this.matches[this.selectedMatchKey].telegramChannel
        };
        this.$mqtt.publish('NewEvent/' + this.matches[this.selectedMatchKey].championshipId + '/' + this.selectedMatchKey, JSON.stringify(obj))
        this.dialog = false;
      }
    },
    created() {
      var that = this;
      that.getMatches();

      that.$root.$on('refreshResults', () => {that.getMatches()});
    }, 
    beforeDestroy() {
      this.$mqtt.unsubscribe('Event/#');
    }
  }
</script>

<style scoped>
  .result {
    font-size: 32px;
    font-weight: bold;
    display: flex;
  }

  .centerSwitch {
    margin-left: auto;
    margin-right: auto;
  }

  .left {
    flex: 1;
    display: flex;
    justify-content: right;
  }

  .center {
    flex: 0.5;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: left;
  }

  .live {
    color: red
  }
</style>
