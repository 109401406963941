import Vue from 'vue'
import Router from 'vue-router'
import {store} from './store/index.js'
import HomePage from './components/HomePage.vue'
import SchedulePage from './components/SchedulePage.vue'
import ResultPage from './components/ResultPage.vue'
import StandingsPage from './components/StandingsPage.vue'
import StandingPage from './components/StandingPage.vue'

import LoginPage from './components/admin/LoginPage.vue'
import AdminPage from './components/admin/AdminPage.vue'

import BarPage from './components/BarPage.vue'
Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: { isAuthenticated: false }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: SchedulePage,
    meta: { isAuthenticated: false }
  },
  {
    path: '/result/:championshipId/:key',
    name: 'result',
    component: ResultPage,
    meta: { isAuthenticated: false }
  },  
  {
    path: '/standings',
    name: 'standings',
    component: StandingsPage,
    meta: { isAuthenticated: false }
  },
  {
    path: '/standing/:championshipId',
    name: 'standing',
    component: StandingPage,
    meta: { isAuthenticated: false }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { isAuthenticated: false }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage,
    meta: { isAuthenticated: true }
  },
  {
    path: '/bar/:championshipId/:key',
    name: 'bar',
    component: BarPage,
    meta: { isAuthenticated: false }
  },
  {
    path: '*',
    name: 'default',
    component: HomePage,
    meta: { isAuthenticated: false }
  }
]

const router = new Router({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated && store.getters.user == null) {
    next({ name: 'login' })
  }
  else {
    next()
  }
})

export default router;