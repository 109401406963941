<template>
  <v-container>
    <v-tabs fixed-tabs v-model="tab">
      <v-tab key="tab-0" style="max-width: 100%">
        Live
      </v-tab>
      <v-tab key="tab-1" style="max-width: 100%">
        Risultati
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="width:100%">
      <v-tab-item key="tab-0">
        <AdminResult :matchToEdit="matchToEdit"></AdminResult>
      </v-tab-item>
      <v-tab-item key="tab-1">
        <AdminSchedule @detail="detail"></AdminSchedule>
      </v-tab-item>
    </v-tabs-items>

    
  </v-container>
</template>

<script>
  import AdminResultComponent from './AdminResultComponent.vue'
  import AdminScheduleComponent from './AdminScheduleComponent.vue'  

  export default {
    name: 'AdminPage',

    components: {
      AdminResult: AdminResultComponent,
      AdminSchedule: AdminScheduleComponent
    },
    data: () => ({
      tab: null,
      matchToEdit: null
    }),
    methods: {
      detail(match) {
        this.tab = 0;
        this.matchToEdit = Object.assign({}, match);
      }
    }
  }
</script>