import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    standing(championshipId) {
        const url = this.webApiBaseUrl + "standing/" + championshipId;
        return this.apiCallerHelper.callGet(url);
    }

    saveStanding(championshipId, data) {
        const url = this.webApiBaseUrl + "standing/" + championshipId;
        return this.apiCallerHelper.callPost(url, data);
    }

    scorers(championshipId) {
        const url = this.webApiBaseUrl + "scorers/" + championshipId;
        return this.apiCallerHelper.callGet(url);
    }

    saveScorers(championshipId, data) {
        const url = this.webApiBaseUrl + "scorers/" + championshipId;
        return this.apiCallerHelper.callPost(url, data);
    }

    image(key) {
        try {
            return '/images/' + key + '.png';
        }
        catch(e) {
            return '/images/test.png';
        }
    }
}