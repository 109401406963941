import ApiCallerHelper from "./apiCallerHelper";
import ApiCallerChampionships from "./apiCallerChampionships";
import ApiCallerResult from "./apiCallerResult";
import ApiCallerStanding from "./apiCallerStanding";
import ApiCallerAdmin from "./apiCallerAdmin";

class Services {

    get apiCallerHelper() {
        return new ApiCallerHelper();
    }

    get ApiCallerChampionships() {
        return new ApiCallerChampionships(this.apiCallerHelper);
    }

    get apiCallerResult() {
        return new ApiCallerResult(this.apiCallerHelper);
    }

    get apiCallerStanding() {
        return new ApiCallerStanding(this.apiCallerHelper);
    }

    get apiCallerAdmin() {
        return new ApiCallerAdmin(this.apiCallerHelper);
    }
}

export var services = new Services();
