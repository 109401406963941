<template>
  <v-skeleton-loader
    class="mx-auto"
    :loading="match == null"
    max-width="300"
    type="list-item"
  >
    <v-card class="pointer" @click="detail()">
      <v-card-subtitle class="pa-1">
          <v-icon>mdi-chevron-right</v-icon>
          <span style="text-decoration: underline" @click.stop="goStanding()"> {{ matchInfo.championship }} </span>
          <span v-if="date" class="ml-2">{{ formattedDate }}</span>
          <div v-if="!edit && matchInfo.video" class="pr-2 right">
            <v-btn icon @click.stop="openVideo()">
              <v-icon v-if="match && match.status == 'In Corso'" color="error">mdi-play-circle-outline</v-icon>
              <v-icon v-else color="primary">mdi-play-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-card-subtitle>
      
      <v-card-title class="text-h6 py-1" style="width:100%">
        <img
          :src="homeImage"
          class="ma-1"
          height="32"
          width="32"
        />
        <template v-if="match != null">
          <div>{{match.teamHome.name}}</div>
          
          <div v-if="match.status == 'In Corso'"
            class="pr-12 centerAbsolute live text">
            LIVE
          </div>
          
          <div v-if="match.status == 'Programmata'"
            class="pr-6 centerAbsolute hour text">
            {{matchInfo.date.substring(11)}}
          </div>
          <div v-else
            class="pr-2" style="margin-left: auto;"
            :class="{live: match.status == 'In Corso'}">
            {{match.teamHome.totalScore}}
          </div>   
          
          <v-btn v-if="edit" class="pl-2 centerAbsolute" icon color="red" @click="remove()">
            <v-icon class="pb-2">mdi-delete</v-icon>
          </v-btn>
        </template>   
      </v-card-title>          

      <v-card-title class="text-h6 py-1" style="width:100%">
        <img
          :src="awayImage"
          class="ma-1"
          height="32"
          width="32"
        />
        <template v-if="match != null">
          <div>{{match.teamAway.name}}</div>
          <div v-if="match.status != 'Programmata'"
            class="pr-2" style="margin-left: auto;"
            :class="{live: match.status == 'In Corso'}">{{match.teamAway.totalScore}}</div>     
        </template>         
      </v-card-title>  
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import moment from 'moment'
import { services } from '../services/serviceBuilder'

export default {
  name: 'MatchItemComponent',

  data: () => ({
    homeImage: null,
    awayImage: null,
    match: null
  }),
  props: {
    matchKey: {
      type: String,
      required: true
    },
    matchInfo: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: false
    }
  },
  mqtt: {
    'Event/' (data, topic) {
      if(!topic.includes(this.matchKey))
        return;
      const parsedData = JSON.parse(new TextDecoder().decode(data));
      this.$set(this.match, 'status', parsedData.match.status);
      this.$set(this.match.teamHome, 'totalScore', parsedData.match.teamHome.totalScore);
      this.$set(this.match.teamAway, 'totalScore', parsedData.match.teamAway.totalScore);
    }
  },
  computed: {
    formattedDate: function() {
      if(this.matchInfo)
        return moment(this.matchInfo.date).format("DD/MM/yyyy");

      return null;
    }
  },
  methods: {
    detail() {
      if(!this.edit){
        this.$router.push({name: 'result', params: { championshipId: this.matchInfo.championshipId, key: this.matchKey }, query: {date: this.matchInfo.date, championship: this.matchInfo.championship, video : this.matchInfo.video}});
      }   
      else {
        var info = this.matchInfo;
        info.key = this.matchKey;
        info.championshipInfo = {
          championshipId: this.matchInfo.championshipId,
          championship: this.matchInfo.championship
        }
        info.teamHome = this.match.teamHome.name;
        info.teamAway = this.match.teamAway.name;
        this.$emit("click", info);
      }     
    },
    remove() {
      this.$emit("remove", this.matchKey);
    },
    getMatch(key, championshipId) {
      var that = this;
      that.$mqtt.subscribe('Event/' + championshipId + '/' + key);
      services.apiCallerResult.match(key, championshipId)
        .then(response => {
          that.match = response.data;
          that.$emit('matchUpdated', that.match);
        })
        .catch(error => {
          console.log(error)
        })
    },
    goStanding() {
      this.$router.push({name: 'standing', params: { championshipId: this.matchInfo.championshipId }});
    },
    openVideo() {
      window.open(this.matchInfo.video, '_blank');
    }
  },
  created() {
    if(this.matchKey != null && this.matchInfo != null) {
      this.homeImage = services.apiCallerResult.image(this.matchKey, 'home');
      this.awayImage = services.apiCallerResult.image(this.matchKey, 'away');
      this.getMatch(this.matchKey, this.matchInfo.championshipId);
    }
  },
  beforeDestroy() {
    this.$mqtt.unsubscribe('Event/#');
  }
};
</script>

<style scoped>
  .centerAbsolute{
    position: absolute;
    top: 50%;
    right: 0%;
    font-size: 1rem;
  } 

  .hour {
    color: darkgrey;
  }
</style>