<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <v-container v-else fluid>
      <v-row>
        <v-col cols="6" sm="6" md="9" class="px-0">
          <v-btn class="mr-2"
            :color="buttonLive ? '' : 'error'"
            @click="toggleButtonLive(false)">
            TUTTE
          </v-btn>
          <v-btn
            :color="buttonLive ? 'error' : ''"
            :disabled="!buttonLiveEnabled"
            @click="toggleButtonLive(true)">
            LIVE
          </v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="px-0">
          <v-spacer></v-spacer>
          <v-dialog
            ref="dialog"
            v-model="dialogDate"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formatDate"
                class="pt-0 ml-auto"
                prepend-icon="mdi-calendar"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
                style="width: 125px"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :events="arrayDates"
              event-color="primary lighten-1"
              scrollable
              locale="it-IT"
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialogDate = false">
                Cancella
              </v-btn>
              <v-btn text color="primary" @click="saveDate">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <div :key="filterDiv">
        <v-row v-for="(item, key, i) in filteredResults" 
          :key="'result-'+i">
          <v-col cols="12" class="px-0 pb-0">
            <MatchItem :matchKey="key" :matchInfo="item" @matchUpdated="matchUpdated(key, $event)"></MatchItem>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="Object.keys(filteredResults).length == 0" class="text-center">
        <v-col cols="12">
          <v-img
            :src="'/logo.png'"
            class="my-3"
            contain
            height="200"
          />
        </v-col> 
        <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Nessun Evento in Programma
        </h1>
      </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import MatchItemComponent from './MatchItemComponent.vue'
  import moment from 'moment'
  import { services } from '../services/serviceBuilder'

  export default {
    name: 'SchedulePage',
    components: {
      MatchItem: MatchItemComponent
    },
    data: () => ({
      loading: true,
      results: {},
      dialogDate: false,
      date: new Date().toISOString().substring(0,10),
      arrayDates: [],
      buttonLive: false,
      filterDiv: 0
    }),
    computed: {
      filteredResults() {
        if(this.buttonLive) {
          return Object.keys(this.results)
            .filter(key => this.results[key].match != null && this.results[key].match.status == "In Corso")
            .reduce((obj, key) => {
              obj[key] = this.results[key];
              return obj;
            }, {});
        }

        return this.results;
      },
      formatDate() {
        return this.date
          ? moment(this.date).format("DD/MM/yyyy")
          : "";
      },
      buttonLiveEnabled() {
        return Object.values(this.results).filter(x => x.match != null && x.match.status == "In Corso").length > 0;
      }
    },    
    methods: {
      getResults() {
        var that = this;
        that.results = {};
        that.loading = true;

        services.apiCallerResult.results(this.date)
          .then(response => {
            that.results = response.data.results;
            that.arrayDates = response.data.dates;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      matchUpdated(key, match) {
        this.results[key].match = match;
        this.results[key] = Object.assign({}, this.results[key]);
      },
      saveDate() {
        this.$refs.dialog.save(this.date);
        this.dialogDate = false;  
        this.getResults();
      },
      toggleButtonLive(value) {
        this.buttonLive = value;
        this.filterDiv += 1;
      }
    },
    created() {
      this.getResults();
    }
  }
</script>
