<template>
  <v-container fluid fill-height>
      <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
              <v-card class="elevation-12">
                  <v-toolbar dark color="primary">
                      <v-toolbar-title>Login</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                  <v-alert dense border="left" type="error"
                    v-if="errorLogin">
                    Username o Password non valide
                  </v-alert>
                  <form ref="form" @submit.prevent="login()">
                          <v-text-field
                          v-model="username"
                          name="username"
                          label="Username"
                          type="text"
                          placeholder="username"
                          required
                      ></v-text-field>
                      
                          <v-text-field
                          v-model="password"
                          name="password"
                          label="Password"
                          type="password"
                          placeholder="password"
                          required
                      ></v-text-field>
                      <v-btn type="submit" class="mt-4" color="primary" value="log in">Login</v-btn>
                  </form>
                  </v-card-text>
              </v-card>
              
          </v-flex>
      </v-layout>
  </v-container>
</template>
 
 <script>
 import { services } from '../../services/serviceBuilder'

 export default {
   name: "LoginPage",
   data() {
     return {
       username: "",
       password: "",
       errorLogin : false
     };
   },
   methods: {
     login() {
      var that = this;
      var data = {
        client_id: 'node-red-editor',
        grant_type: 'password',
        scope: '',
        username: that.username,
        password: that.password
      }
      services.apiCallerAdmin.login(data)
          .then(() => {
            that.errorLogin = false;
            services.apiCallerAdmin.setUsername(this.username);
            that.$router.push('/admin');
          })
          .catch(error => {
            console.log(error)
            that.errorLogin = true;
          })
          .finally(() => {
            that.loading = false;
          })
     },
   },
 };
 </script>