<template>
    <v-footer dark padless>
      <v-card
        flat
        tile
        color="primary"
        class="white--text text-center"
        style="width:100%"
      >
        <v-card-text >
          <v-btn
            v-for="(icon,i) in icons"
            :key="'icon-' + i"
            class="mx-4 white--text"
            icon
            @click="openIcon(icon)"
          >
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>{{footerName}}</strong>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
import AppSettingsService from '../services/appSettingsService';

export default {
  name: 'FooterComponent',
  data: () => ({
    footerName: '',
    icons: []
  }),

  methods: {
    openIcon(icon) {
      window.open(icon.href, '_blank');
    }
  },
  created() {
    var that = this;
    AppSettingsService.getConfiguration()
      .then(conf => {
        that.icons = conf.socials.map(x => {
          x.icon = 'mdi-' + x.name;
          return x;
        });
        that.footerName = conf.footerName;
      })
  }
};
</script>
