<template>
	<v-btn color="success" :disabled="disabled" @click="downloadFile">
		<v-icon left> mdi-file-excel-box </v-icon>
		Excel
	</v-btn>
</template>

<script>
import { Workbook } from "exceljs";
import * as fs from "file-saver";

export default {
	name: "DownloadExcel",
	data: function () {
		return {
			maxLength: 90,
			minLength: 10,
		};
	},
	props: {
		dataFilters: null,
		dataHeaders: null,
		dataObject: null,
		fileName: {
			type: String,
			default: null,
		},
		fileType: {
			type: String,
			default: "xlsx",
			validator: function (value) {
				return ["xlsx", "csv"].indexOf(value) !== -1;
			},
		},
		fitColumns: {
			type: Boolean,
			default: true,
		},
		frozenView: {
			type: Boolean,
			default: true,
		},
		frozenViewSplitX: {
			type: Number,
			default: 1,
		},
		frozenViewSplitY: {
			type: Number,
			default: 1,
		},
		sheetName: {
			type: String,
			default: "Data",
		},
	},
	computed: {
		disabled: function () {
			return !this.dataObject || this.dataObject.length == 0;
		},
		fullFileName: function () {
			return (this.fileName && this.fileName != "" ? this.fileName : this.generateFileName()) + "." + this.fileType;
		},
	},
	methods: {
		addData: function (worksheet) {
			let that = this;

			for (let x1 of that.dataObject) {
				let x2 = Object.keys(x1);
				let temp = [];

				that.dataHeaders.forEach(function (item) {
					var value = x2.indexOf(item.value) > -1 ? x1[item.value] : null;
					if (value && Object.hasOwn(item, "type") && ["date", "datetime", "time"].indexOf(item["type"]) > -1) {
						value = new Date(value);
					}
					temp.push(value);
				});

				worksheet.addRow(temp);
			}
		},
		addFilters: function (worksheet) {
			let that = this;

			var temp = [];

			that.dataFilters.forEach(function (item) {
				var value = item.value;
				if (value && Object.hasOwn(item, "type") && ["date", "datetime", "time"].indexOf(item["type"]) > -1) {
					value = new Date(value);
				}
				temp.push(value);
			});

			worksheet.addRow(temp);
		},
		addHeaders: function (worksheet, headers) {
			// add headers
			let headersRow = worksheet.addRow(headers);

			// style header row
			headersRow.eachCell(function (cell) {
				if (cell.value && cell.value != "") {
					cell.font = {
						bold: true,
						color: {
							argb: "FFFFFF",
						},
					};

					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "A9A9A9" },
					};
				}
			});
		},
		addWorksheetData: function (workbook) {
			let that = this;

			// add worksheet to workbook
			let worksheet = workbook.addWorksheet(that.sheetName);

			// add headers
			var headers = that.dataHeaders.map(function (item) {
				return item.name;
			});
			that.addHeaders(worksheet, headers);

			// set data format
			that.setDataFormat(worksheet, that.dataHeaders);

			// frozen view
			if (that.frozenView) {
				worksheet.views = [
					{
						state: "frozen",
						xSplit: that.frozenViewSplitX,
						ySplit: that.frozenViewSplitY,
					},
				];
			}

			// add data rows
			that.addData(worksheet);

			// fit columns
			if (that.fitColumns) that.fitCols(worksheet);
		},
		addWorksheetFilters: function (workbook) {
			let that = this;

			// add worksheet to workbook
			let worksheet = workbook.addWorksheet("Filters");

			var headers = that.dataFilters.map(function (item) {
				return item.name;
			});
			that.addHeaders(worksheet, headers);

			// set data format
			that.setDataFormat(worksheet, that.dataFilters);

			// add data rows
			that.addFilters(worksheet);

			// fit columns
			if (that.fitColumns) that.fitCols(worksheet);
		},
		downloadFile: function () {
			let that = this;

			// create a new workbook
			let workbook = new Workbook();

			// add worksheet data
			that.addWorksheetData(workbook);

			// add worksheet filters
			if (that.dataFilters && that.dataFilters.length > 0) that.addWorksheetFilters(workbook);

			// save file
			that.saveFile(workbook);
		},
		fitCols: function (worksheet) {
			let that = this;

			worksheet.columns.forEach(function (column) {
				var length = 0;

				column["eachCell"]({ includeEmpty: true }, function (cell) {
					if (cell.type != 4) {
						var columnLength = cell.value ? cell.value.toString().length + 2 : that.minLength;
						if (columnLength > length) length = columnLength;
					} else {
						length = cell.numFmt && cell.numFmt.length > 0 ? cell.numFmt.length + 2 : 15;
					}
				});

				column.width = that.getColumnWidth(length);
			});
		},
		getColumnWidth(length) {
			let that = this;
			var width = length;

			if (length < that.minLength) {
				width = that.minLength;
			} else if (length > that.maxLength) {
				width = that.maxLength;
			}

			return width;
		},
		generateFileName: function () {
			let that = this;

			var date = new Date();
			return (
				"File_" +
				date.getFullYear().toString() +
				that.padding2(date.getMonth() + 1) +
				that.padding2(date.getDate()) +
				that.padding2(date.getHours()) +
				that.padding2(date.getMinutes()) +
				that.padding2(date.getSeconds())
			);
		},
		getDefaultFormat: function (type) {
			switch (type) {
				case "date":
					return "DD MMM YYYY";
				case "datetime":
					return "DD MMM YYYY HH:mm:ss";
				case "time":
					return "HH:mm:ss";
				case "integer":
					return "#,##0";
				case "double":
					return "#,##0.00";
				default:
					return null;
			}
		},
		padding2: function (value) {
			return value < 10 ? "0" + value : value;
		},
		setDataFormat: function (worksheet, headers) {
			let that = this;

			for (var i = 0, l = headers.length; i < l; i++) {
				if (Object.hasOwn(headers[i], "type")) {
					var type = headers[i]["type"].toLowerCase();
					if (["date", "datetime", "time", "integer", "double"].indexOf(type) > -1) {
						var format = that.getDefaultFormat(type);
						if (Object.hasOwn(headers[i], "format") && headers[i]["format"] && headers[i]["format"].length > 0) {
							format = headers[i]["format"];
						}
						if (format)
							worksheet.getColumn(i + 1).style = {
								numFmt: format,
							};
					}
				}
			}
		},
		saveFile: function (workbook) {
			let that = this;

			if (that.fileType == "xlsx") {
				workbook.xlsx.writeBuffer().then(data => {
					let blob = new Blob([data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					fs.saveAs(blob, that.fullFileName);
				});
			} else {
				workbook.csv.writeBuffer().then(data => {
					let blob = new Blob([data], {
						type: "text/csv;charset=utf-8",
					});
					fs.saveAs(blob, that.fullFileName);
				});
			}
		},
	},
};
</script>
