import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    championships() {
        const url = this.webApiBaseUrl + "championships";
        return this.apiCallerHelper.callGet(url);
    }

    saveChampionships(data) {
        const url = this.webApiBaseUrl + "championships";
        return this.apiCallerHelper.callPost(url, data);
    }
}