<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <v-container v-else fluid>
      <v-row class="text-center px-5">
        <v-col cols="12" class="championshipName">
          {{ standing.championship }}
        </v-col>
      </v-row>

      <v-row class="pt-0 borderPrimary">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab v-for="(tab) in tabs" :key="tab.key" style="max-width: 100%">
            {{tab.name}}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="width:100%">
          <v-tab-item v-for="(tab) in tabs" :key="tab.key"> 
            <template v-if="tab.key == 'tab-0'">
              <v-row class="mt-2 pr-4" v-if="edit">
                <v-spacer></v-spacer>
                <v-btn @click="saveStanding()" color="primary">Salva</v-btn>
              </v-row>
              <v-row class="text-center pt-0">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="filteredColumnsStanding"
                    :items="itemsStanding"
                    item-key="index"
                    class="elevation-1"
                    :items-per-page=10
                    hide-default-footer
                    :mobile-breakpoint="edit ? 600 : 0"
                  >
                  <template v-slot:[`item.w`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStats('w', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <span>{{item.w}}</span>
                    <v-btn v-if="edit" icon @click="setStats('w', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.n`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStats('n', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <span>{{item.n}}</span>
                    <v-btn v-if="edit" icon @click="setStats('n', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.l`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStats('l', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <span>{{item.l}}</span>
                    <v-btn v-if="edit" icon @click="setStats('l', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.gf`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStats('gf', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <span>{{item.gf}}</span>
                    <v-btn v-if="edit" icon @click="setStats('gf', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.gs`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStats('gs', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <span>{{item.gs}}</span>
                    <v-btn v-if="edit" icon @click="setStats('gs', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <v-img
                        :src="item.image"
                        class="ma-0 pa-0"
                        width="48"
                        height="48"
                      />
                      <span>{{item.number}}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>   
            </template>
            <template v-else-if="tab.key == 'tab-1'">
              <v-row class="mt-2 pr-4">
                <v-spacer></v-spacer>
                <DownloadExcel
                  class="mr-2"
                  :data-object="itemsScorers"
                  :data-headers="getHeadersXls()"
                  :data-filters="getDataFilters()"
                  :file-name="'Classifica Marcatori ' + standing.championship"
                ></DownloadExcel>
                <v-btn v-if="edit" @click="dialogScorer = true" class="mr-2" color="primary">Aggiungi</v-btn>
                <v-btn v-if="edit" @click="saveScorers()" color="primary">Salva</v-btn>
              </v-row>
              <v-row class="mt-2 mx-0 px-4">
                <v-spacer></v-spacer>
                <div class="searchBar">
                  <v-text-field
                    v-model="searchScorers"
                    label="Cerca"
                    class="mx-0"
                    append-icon="mdi-magnify"
                    single-line hide-details
                  ></v-text-field>
                </div>
              </v-row>
              <v-row class="text-center pt-0">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="filteredColumnsScorers"
                    :items="itemsScorers"
                    item-key="index"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: [20, 50, -1],
                      itemsPerPageText: 'Giocatori',
                      itemsPerPageAllText: 'Tutti'
                    }"
                    mobile-breakpoint="0"
                    :search="searchScorers"
                  >
                  <template v-slot:[`item.name`]="{ item }">
                    <v-text-field v-if="edit" v-model="item.name"></v-text-field>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template v-slot:[`item.scores`]="{ item }">
                    <v-btn v-if="edit" icon @click="setStatsScorers('scores', true, item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <div class="d-flex align-center">
                      <span>{{item.scores}}</span> 
                      <v-btn v-if="edit" icon color="red" @click="deleteScorer(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn> 
                    </div>                
                    <v-btn v-if="edit" icon @click="setStatsScorers('scores', false, item)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>                  
                  </template>
                  <template v-slot:[`item.image`]="{ item }">
                    <v-img
                      :src="item.image"
                      class="ma-0 pa-0"
                      width="48"
                      height="48"
                    />
                    <span>{{item.number}}</span>
                  </template>
                  </v-data-table>
                </v-col>
              </v-row>  
              <v-row>
                <v-col cols="12">
                  <v-textarea v-if="edit" outlined v-model="scorers.notes"></v-textarea>
                  <div v-else style="white-space: pre-wrap;">{{scorers.notes}}</div>
                </v-col>
              </v-row> 
            </template>
            <template v-else>
              <div class="py-4">
                <v-row class="mx-2"  v-for="(item, key, j) in results" 
                  :key="'result-'+j">
                  <v-col cols="12" class="px-0 pb-0">
                    <MatchItem :matchKey="key" :matchInfo="item" :date="true"></MatchItem>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-tab-item>
        </v-tabs-items> 
      </v-row>

      <v-dialog
        v-model="dialogScorer"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Aggiungi Giocatore</v-card-title>

          <v-card-text>
            <v-text-field v-model="newScorer.name" label="Nome"></v-text-field>
            <v-text-field v-model="newScorer.team" label="Squadra"></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!btnSaveScorerEnabled" @click="saveScorer()">Salva</v-btn>
            <v-btn color="error" @click="cancelScorer()">Chiudi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
  import MatchItemComponent from './MatchItemComponent.vue'
  import DownloadExcelComponent from './DownloadExcelComponent.vue'
  import { services } from '../services/serviceBuilder'

  export default {
    name: 'StandingPage',
    components: {
      MatchItem: MatchItemComponent,
      DownloadExcel: DownloadExcelComponent
    },
    data: () => ({
      loading: true,
      standing: {},
      scorers: {},
      results: {},
      tab: "0",
      tabs: [],
      edit: false,
      columnsStanding: [
        { text: 'Pos', value: 'index', sortable: false, align: 'center', width: '10px' },
        { text: '', value: 'image', sortable: false, align: 'center', width: '20px', class: "pa-0", isMobile: true },
        { text: 'Squadra', value: 'name', sortable: false, isMobile: true },
        { text: 'Punti', value: 'points', align: 'center', width: '10px', isMobile: true },
        { text: 'V', value: 'w', align: 'center', width: '10px' },
        { text: 'N', value: 'n', align: 'center', width: '10px' },
        { text: 'P', value: 'l', align: 'center', width: '10px' },
        { text: 'G', value: 'g', align: 'center', width: '10px', isMobile: true },
        { text: 'Gf', value: 'gf', align: 'center', width: '10px' },
        { text: 'Gs', value: 'gs', align: 'center', width: '10px' },
        { text: 'Dr', value: 'dr', align: 'center', width: '10px', isMobile: true },
      ],
      columnsScorers: [
        { text: 'Pos', value: 'index', sortable: false, align: 'center', width: '10px', isMobile: true },
        { text: '', value: 'image', align: 'center', width: '20px', class: "pa-0", isMobile: true },
        { text: 'Nome', value: 'name', isMobile: true },
        { text: 'Goal', value: 'scores', align: 'center', width: '10px', isMobile: true},
      ],
      filteredColumnsStanding: [],
      filteredColumnsScorers: [],
      itemsStanding: [],
      itemsScorers: [],
      searchScorers: '',
      dialogScorer: false,
      newScorer: {}
    }),
    computed: {
      btnSaveScorerEnabled: function() {
        return (this.newScorer && this.newScorer.name && this.newScorer.team);
      },
    },
    methods: {
      getStanding() {
        return new Promise(resolve => {
          var that = this;
          that.standing = {};
          that.itemsStanding = [];

          services.apiCallerStanding.standing(this.$route.params.championshipId)
            .then(response => {
              that.standing = response.data;
              for (const item of that.standing.entries) {
                item.image = services.apiCallerStanding.image(item.key);
                item.points = item.w * 3 + item.n * 1;
                item.g = item.w + item.n + item.l;
                item.dr = item.gf - item.gs;
              }

              that.sortItemsStanding(that.standing.entries);
              resolve(true);
            })
            .catch(error => {
              console.log(error)
              resolve(false);
            })
        })
      },
      getScorers() {
        return new Promise(resolve => {
          var that = this;
          that.scorers = {};
          that.itemsScorers = [];

          services.apiCallerStanding.scorers(this.$route.params.championshipId)
            .then(response => {
              that.scorers = response.data;
              for (const item of that.scorers.entries) {               
                item.image = services.apiCallerStanding.image(item.team);
              }
              that.sortItemsScorers(that.scorers.entries);
              resolve(true);
            })
            .catch(error => {
              console.log(error)
              resolve(false);
            })
        })
      },
      getResults() {
        return new Promise(resolve => {
          var that = this;
          that.results = {};

          services.apiCallerResult.results(null)
            .then(response => {
              var results = {};
              for (const [key, value] of Object.entries(response.data)) {
                if(value.championshipId == this.$route.params.championshipId) {
                  results[key] = value;
                }
              }
              that.results = Object.assign({}, results);    
              resolve(!this.edit);      
            })
            .catch(error => {
              console.log(error)
              resolve(false);
            })
        })        
      },
      getHeadersXls: function () {
        return [
            {
              name: "Pos",
              value: "index",
            },
            {
              name: "Nome",
              value: "name",
            },
            {
              name: "Team",
              value: "team",
            },
            {
              name: "Goal",
              value: "scores",
            }
        ];
      },
      getDataFilters: function () {
       var dataFilters = [];

        // extraction date
        dataFilters.push({
          name: "Extraction date",
          value: new Date(),
          type: "datetime",
        });

        // separation column
        dataFilters.push({
          name: "",
          value: null,
        });

        return dataFilters;
      },
      saveStanding() {
        var that = this;
        that.loading = true;

        that.standing.entries = that.itemsStanding;
        that.standing.entries.forEach(object => {
          delete object['image'];
        });
        services.apiCallerStanding.saveStanding(this.$route.params.championshipId, that.standing)
          .then(() => { })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
            that.getStanding();
          })
      },
      saveScorers() {
        var that = this;
        that.loading = true;

        that.scorers.entries = that.itemsScorers;
        that.scorers.entries.forEach(object => {
          delete object['image'];
        });
        services.apiCallerStanding.saveScorers(this.$route.params.championshipId, that.scorers)
          .then(() => { })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
            that.getScorers();
          })
      },
      deleteScorer(item) {
        var that = this;
        that.loading = true;
        var index = that.itemsScorers.findIndex(x => x.name == item.name && x.team == item.team);
        if(index != -1) {
          that.itemsScorers.splice(index, 1);
        }
        that.saveScorers();
      },
      saveScorer() {
        var that = this;
        that.loading = true;
        that.newScorer.scores = 0;
        that.itemsScorers.push(that.newScorer);
        that.saveScorers();
        that.cancelScorer();
      },
      cancelScorer() {
        this.dialogScorer = false;   
        this.newScorer = {};
      },
      onResize(isMobile) {
        if(this.edit){
          this.filteredColumnsStanding = this.columnsStanding.filter(x => !isMobile || x.value != 'index' && x.value != 'image');
        }
        else {
          this.filteredColumnsStanding = this.columnsStanding.filter(x => !isMobile || x.isMobile);
        } 

        this.filteredColumnsScorers = this.columnsScorers.filter(x => !isMobile || x.isMobile);       
      },
      setStats(stat, up, item) {
        if(up) {
          item[stat]++;
        }
        else {
          item[stat]--;
        }

        item.points = item.w * 3 + item.n * 1;
        item.g = item.w + item.n + item.l;
        item.dr = item.gf - item.gs;

        this.sortItemsStanding(this.itemsStanding);
      },
      setStatsScorers(stat, up, item) {
        if(up) {
          item[stat]++;
        }
        else {
          item[stat]--;
        }

        this.sortItemsScorers(this.itemsScorers);
      },
      sortItemsStanding(items) {
        var that = this;
        items = items.sort((a,b) => {
          if(b.points - a.points != 0)
            return b.points - a.points;
          else if(b.w - a.w != 0)
            return b.w - a.w;
          else if(b.n - a.n != 0)
            return b.n - a.n;
          else if(b.l - a.l != 0)
            return a.l - b.l;
          else if(b.dr - a.dr != 0)
            return b.dr - a.dr
          else if(b.gf - a.gf != 0)
            return b.gf - a.gf;
          else if(b.gs - a.gs != 0)
            return b.gs - a.gs;
          else {
            return that.compareString(a.name, b.name);
          }
        });

        this.itemsStanding = items.map((x, index) => {x.index = index + 1; return x;});
      },
      sortItemsScorers(items) {
        var that = this;
        items = items.sort((a,b) => {
          if(b.scores - a.scores != 0)
            return b.scores - a.scores;
          else if(a.matches - b.matches != 0)
            return a.matches - b.matches;
          else {
            return that.compareString(a.name, b.name);
          }
        });

        this.itemsScorers = items.map((x, index) => {x.index = index + 1; return x;});
      },
      compareString(a, b) {
        a = a.toUpperCase();
        b = b.toUpperCase();
        if(a < b)
          return -1;
        if(a > b)
          return 1;
        return 0;
      }
    },    
    created() {
      var that = this;

      if(that.$route.params.championshipId == "") {
        that.$router.push({name: 'schedule'});
      }

      if(services.apiCallerAdmin.getUsername() != null) {
        that.edit = true;
      }

      that.onResize(window.innerWidth < 600);
      that.$root.$on('isMobile', that.onResize);

      var promises = [];
      this.loading = true;
      promises.push(this.getStanding());
      promises.push(this.getScorers());
      promises.push(this.getResults());
      Promise.all(promises)
        .then(results => {
          var tabNames = ["Classifica", "Marcatori", "Risultati"];
          for(var i = 0; i < results.length; i++) {
            if(results[i]) {
              this.tabs.push({
                key: 'tab-' + i,
                name: tabNames[i]
              })
            }
          }
          this.loading = false;
        })
    }
  }
</script>

<style scoped>
  .championshipName {
    font-size: 22px;
    font-weight: bold;
  }

  .searchBar {
    width: 400px;
    max-width: 100%;
  }
</style>
