import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { store } from "./store";

import "./assets/style.css";

import axios from 'axios'
import VueMqtt from 'vue-mqtt'
import AppSettingsService from "./services/appSettingsService";

let webSocketOptions = AppSettingsService.getSettings().webSocketOptions;
webSocketOptions.clientId = 'WebClient-' + parseInt(Math.random() * 100000);
Vue.use(VueMqtt, AppSettingsService.getSettings().webSocketUrl, webSocketOptions);

Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
