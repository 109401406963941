<template>
    <v-container>
        <div class="text-center" v-if="loading">
            <v-progress-circular
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
        <v-row v-else class="text-center px-0">
            <v-col cols="3" class="pa-0 leftBg">
                <v-img
                    :src="homeImage"
                    class="ma-1"
                    contain
                    height="80"
                />
            </v-col>
            <v-col cols="6" class="py-0 centerBg">
                <div class="result"
                    :class="{live: match.status == 'In Corso'}"
                    >
                    <div class="left" style="color: #154c79">{{match.teamHome.totalScore}}</div>
                    <div class="center"></div>
                    <div class="right"  style="color: #FFFFFF">{{match.teamAway.totalScore}}</div>
                </div>
                <!-- <h3 v-if="match.status == 'In Corso'">{{match.time}}</h3> -->
            </v-col>
            <v-col cols="3" class="pa-0 rightBg">
                <v-img
                    :src="awayImage"
                    class="ma-1"
                    contain
                    height="80"
                />
            </v-col>
            <v-col cols="4" offset="4" class="pa-0 mt-1 timeBg" 
              v-if="match.status == 'In Corso'">
              <h2>{{match.time}}</h2>
            </v-col>
        </v-row>
    </v-container>
</template>
  
  <script>
    import { services } from '../services/serviceBuilder'
  
    export default {
      name: 'BarPage',
  
      data: () => ({
        loading: true,
        match: null,
        homeImage: null,
        awayImage: null
      }),
      mqtt: {
        'Event/#' (data, topic) {
          if(!topic.includes(this.$route.params.key))
            return;
          const parsedData = JSON.parse(new TextDecoder().decode(data));
          this.match = parsedData.match;
        }
      },
      methods: {
      },
      created() {
        var that = this;
        that.loading = true;
  
        if(that.$route.params.key == "" ||
          that.$route.params.championshipId == "") {
          that.$router.push({name: 'home'});
        }
  
        services.apiCallerResult.match(that.$route.params.key, that.$route.params.championshipId)
          .then(response => {
            that.match = response.data;
            that.homeImage = services.apiCallerResult.image(that.$route.params.key, 'home');
            that.awayImage = services.apiCallerResult.image(that.$route.params.key, 'away');
            that.$mqtt.subscribe('Event/' + that.$route.params.championshipId + '/' + that.$route.params.key);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            that.loading = false;
          })
      },
      beforeDestroy() {
        this.$mqtt.unsubscribe('Event/#');
      }
    }
  </script>
  
  <style scoped>
    .result {
      font-size: 64px;
      font-weight: bold;
      display: flex;
    }
  
    .left {
      flex: 1;
      display: flex;
      justify-content: right;
    }
  
    .center {
      flex: 0.5;
    }
  
    .right {
      flex: 1;
      display: flex;
      justify-content: left;
    }

    .leftBg {
      border: 1px solid; 
      border-radius: 25px 0px 0px 25px; 
      border-color: #FFFFFF; 
      background-color: #FFFFFF;
    }

    .centerBg {
      background: linear-gradient(90deg, #FFFFFF 50%, #154c79 50%);
    }

    .rightBg {
      border: 1px solid; 
      border-radius: 0px 25px 25px 0px; 
      border-color: #154c79; 
      background-color: #154c79;
    }
  
    .timeBg {
      border: 1px solid #FFFFFF; 
      border-radius: 12px; 
      background-color: #FFFFFF; 
    }
  </style>
  